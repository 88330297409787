import { useTranslate, useUpdate } from "@pankod/refine-core"
import { contactDetailPrimaryByType } from "modules/ContactDetails/contactDetailPrimaryNotification"
import { isEmptyString } from "utilities/string"
import { IHost_from_list, IVisitorUpdate, IVisitVisitor } from "interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBriefcase,
  faEnvelope,
  faPencilAlt,
  faPhone,
  faUser,
  faXmark
} from "@fortawesome/free-solid-svg-icons"
import { contactDetailType } from "modules/ContactDetails/contactDetailType"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import {
  addAdHocVisitor,
  removeAdHocVisitor
} from "reduxStore/reducers/adHocVisitorReducer"
import { useAppDispatch } from "reduxStore/store"
import ErrorText from "components/formMessage"
import validators from "utilities/formValidator"
import { LOG, Logger } from "utilities/logger"
import { UserIcon } from "@heroicons/react/24/outline"

// TODO: Move to interfaces file. Fix conflicting interfaces with same name

export const Tag = ({
  visitor,
  host,
  setVisitHost,
  css,
  index
}: {
  visitor?: IVisitVisitor
  host?: IHost_from_list
  setVisitHost?: Dispatch<SetStateAction<IHost_from_list | undefined>>
  css?: string
  index?: number
}) => {
  const translate = useTranslate()
  const dispatch = useAppDispatch()
  const { mutate } = useUpdate<IVisitorUpdate>()

  const [showFloatingInfo, setShowFloatingInfo] = useState(false)
  const [floatingInfoRight, setFloatingInfoRight] = useState(false)
  const floatingInfoRef = useRef<HTMLDivElement>(null)

  // Visitor edit variables
  const [visitorEditMode, setVisitorEditMode] = useState(false)
  const [firstName, setFirstName] = useState(visitor?.first_name ?? "")
  const [lastName, setLastName] = useState(visitor?.last_name ?? "")
  const [email, setEmail] = useState(visitor?.email ?? "")
  const [phone, setPhone] = useState(visitor?.phone ?? "")
  const [company, setCompany] = useState(visitor?.company ?? "")
  const [errors, setErrors] = useState<string[]>([])

  let timeoutId: NodeJS.Timeout | undefined
  let timeoutFloat: NodeJS.Timeout | undefined
  const timeoutDelay = 1400 // Delay of 1.4 seconds

  useEffect(() => {
    const handleResize = () => {
      if (floatingInfoRef.current) {
        // Looks wild but it gets the wider parent element of drawer
        // Checks if floating info is outside of parent element
        // If it is, set floating info to right side
        const parentRect =
          floatingInfoRef.current.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.getBoundingClientRect()
        const floatingRect = floatingInfoRef.current.getBoundingClientRect()

        if (parentRect && floatingRect) {
          setFloatingInfoRight(floatingRect.right > parentRect.right)
        }
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [showFloatingInfo, visitorEditMode])

  const handleMouseEnter = () => {
    timeoutId = setTimeout(() => {
      setShowFloatingInfo(true)
    }, timeoutDelay)
  }

  const handleMouseLeave = () => {
    if (!showFloatingInfo) {
      clearTimeout(timeoutId)
      setVisitorEditMode(false)
      setShowFloatingInfo(false)
    } else {
      timeoutFloat = setTimeout(() => {
        setShowFloatingInfo(false)
      }, timeoutDelay)
    }
  }

  const handleMouseFloatingEnter = () => {
    if (showFloatingInfo) {
      clearTimeout(timeoutFloat)
    }
  }

  const handleMouseFloatingLeave = () => {
    timeoutFloat = setTimeout(() => {
      setShowFloatingInfo(false)
      setVisitorEditMode(false)
    }, timeoutDelay)
  }

  const toggleVisitorEditMode = () => {
    setFirstName(visitor?.first_name ?? "")
    setLastName(visitor?.last_name ?? "")
    setEmail(visitor?.email ?? "")
    setPhone(visitor?.phone ?? "")
    setCompany(visitor?.company ?? "")

    if (showFloatingInfo) {
      clearTimeout(timeoutFloat)
    }
    setVisitorEditMode(true)
  }

  /**
   * Validate fields
   * @returns True if no errors
   */
  function validate(): boolean {
    const validationErrors = validators.handleValidation(
      firstName,
      lastName,
      phone,
      email,
      {
        firstName: true,
        lastName: true,
        phone: false,
        email: false
      }
    )
    setErrors(validationErrors)
    return validationErrors["firstName"] ||
      validationErrors["lastName"] ||
      validationErrors["email"] ||
      validationErrors["phone"]
      ? false
      : true
  }

  const handleEditVisitor = (adHocVisitor: IVisitVisitor) => {
    if (validate()) {
      // If visitorid is found, use put to edit visitor
      // else use removeAdhocVisitor and create new with addAdHocVisitor from fields values
      if (adHocVisitor.visitorId) {
        const visitorId = adHocVisitor.visitorId

        mutate(
          {
            resource: `visitors`,
            id: adHocVisitor.visitorId,
            values: {
              email: email
                ? [
                    {
                      value: email
                    }
                  ]
                : [],
              phone: phone
                ? [
                    {
                      value: phone
                    }
                  ]
                : [],
              first_name: firstName,
              last_name: lastName,
              company: company
            }
          },
          {
            onError: (error) => {
              void Logger().error(LOG.EDIT_VISITOR, `${error.stack}`)
            },
            onSuccess: () => {
              dispatch(
                removeAdHocVisitor({
                  index: index
                })
              )

              dispatch(
                addAdHocVisitor({
                  state: "signed_in",
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone: phone,
                  company: company,
                  visitorId: visitorId
                })
              )

              setVisitorEditMode(false)
              setShowFloatingInfo(false)
            }
          }
        )
      } else {
        dispatch(
          removeAdHocVisitor({
            index: index
          })
        )

        dispatch(
          addAdHocVisitor({
            state: "signed_in",
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            company: company
          })
        )

        setVisitorEditMode(false)
        setShowFloatingInfo(false)
      }
    }
  }

  return (
    <div className="flex" key={index}>
      {showFloatingInfo && (
        <div
          className={`absolute bg-white p-4 min-w-72 border border-gray-300 rounded-md z-100 shadow-md top-9 ${
            floatingInfoRight ? "right-0" : "left-0"
          }`}
          onMouseEnter={handleMouseFloatingEnter}
          onMouseLeave={handleMouseFloatingLeave}
          ref={floatingInfoRef}
        >
          {host && (
            <>
              <div className="flex flex-row center gap-2 mb-4">
                <div className="items-center center">
                  {isEmptyString(host?.picture) ? (
                    <UserIcon className="h-8 w-8 text-systam-blue" />
                  ) : (
                    <img
                      alt="host person"
                      width="80"
                      height="80"
                      className="rounded-full object-cover aspect-square"
                      src={host?.picture}
                    />
                  )}
                </div>
                <div className="text-xl font-medium text-gray-900">
                  {host?.first_name} {host?.last_name}
                  <p className="text-md font-normal text-gray-900">
                    {host?.person.organization_name}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap flex-col">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  {translate("table.visits.contact")}
                </h3>
                <span className="mb-2 text-base text-gray-900">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="lg"
                    className="text-systam-blue mr-2"
                  />{" "}
                  {contactDetailPrimaryByType({
                    contactDetails: host?.contact_details,
                    contactDetailType: contactDetailType.email
                  })}
                </span>
                <span className="mb-2 text-base text-gray-900">
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="lg"
                    className="text-systam-blue mr-2"
                  />{" "}
                  {contactDetailPrimaryByType({
                    contactDetails: host?.contact_details,
                    contactDetailType: contactDetailType.phone
                  })}
                </span>
              </div>
              <div className="flex flex-wrap flex-col mt-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  {translate("workspaces")}
                </h3>
                <span className="mb-2 text-base text-gray-900">
                  {host?.workspaces.map((workspace) => (
                    <div key={workspace.id}>{workspace.workspace.name}</div>
                  ))}
                </span>
              </div>
            </>
          )}
          {visitor && (
            <>
              {visitorEditMode ? (
                <div className="w-72">
                  <div className="flex flex-col gap-1 w-full">
                    <div className="flex flex-row items-center gap-2 px-2">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faUser}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      <input
                        className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                        value={firstName}
                        placeholder={translate("form.firstName")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFirstName(e.target.value)
                        }
                      />
                    </div>
                    {errors["firstName"] && ErrorText(errors["firstName"])}
                    <div className="flex flex-row items-center gap-2 px-2">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faUser}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      <input
                        className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                        placeholder={translate("form.lastName")}
                        value={lastName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setLastName(e.target.value)
                        }
                      />
                    </div>
                    {errors["lastName"] && ErrorText(errors["lastName"])}
                    <div className="flex flex-row items-center gap-2 px-2">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faEnvelope}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      <input
                        className="text-black text-sm  placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                        value={email}
                        placeholder={translate("form.email")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setEmail(e.target.value)
                        }
                      />
                    </div>
                    {errors["email"] && ErrorText(errors["email"])}
                    <div className="flex flex-row items-center gap-2 px-2">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faPhone}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      <input
                        className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                        value={phone}
                        placeholder={translate("form.phone")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setPhone(e.target.value)
                        }
                      />
                    </div>
                    {errors["phone"] && ErrorText(errors["phone"])}
                    <div className="flex flex-row items-center gap-2 px-2">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faBriefcase}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      <input
                        className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                        value={company}
                        placeholder={translate("form.company")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setCompany(e.target.value)
                        }
                      />
                    </div>
                    <div className="flex flex-row justify-between border-t border-gray-300 mx-2 px-2 mt-2">
                      <button
                        className=""
                        onClick={() => setVisitorEditMode(false)}
                      >
                        <p className="text-black">
                          {translate("buttons.cancel")}
                        </p>
                      </button>
                      <div
                        className="cursor-pointer py-2 pt-1 mt-1 flex hover:bg-gray-100 items-center  rounded-b-md"
                        onClick={() =>
                          handleEditVisitor({
                            state: "signed_in",
                            first_name: firstName,
                            last_name: lastName,
                            email: email,
                            phone: phone,
                            company: company,
                            visitorId: visitor?.visitorId
                          })
                        }
                      >
                        <p className="text-blue-500">
                          {translate("buttons.save")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex flex-col gap-1 w-72">
                    <div className="flex flex-row items-center gap-2 px-2 w-full">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faUser}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      <div className="flex flex-row w-full justify-between">
                        <p>
                          {visitor.first_name} {visitor.last_name}
                        </p>
                        <button
                          className="px-2"
                          onClick={() => {
                            toggleVisitorEditMode()
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            size="xs"
                            className="text-black"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-2 px-2">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faEnvelope}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      {visitor.email && !isEmptyString(visitor.email) ? (
                        <p className="text-black">{visitor.email}</p>
                      ) : (
                        <p className="text-gray-400">
                          {translate("xNotFound", {
                            resource: translate("form.email")
                          })}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row items-center gap-2 px-2">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faPhone}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      {visitor.phone && !isEmptyString(visitor.phone) ? (
                        <p className="text-black">{visitor.phone}</p>
                      ) : (
                        <p className="text-gray-400">
                          {translate("xNotFound", {
                            resource: translate("form.phone")
                          })}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row items-center gap-2 px-2">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faBriefcase}
                        className="text-gray-400"
                        style={{ width: "1.5rem" }}
                      />
                      {visitor.company && !isEmptyString(visitor.company) ? (
                        <p className="text-black">{visitor.company}</p>
                      ) : (
                        <p className="text-gray-400">
                          {translate("xNotFound", {
                            resource: translate("form.company")
                          })}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
      <div
        className={`flex flex-row px-1 py-1 rounded-sm border items-center cursor-pointer ${
          css && css
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (!showFloatingInfo) setShowFloatingInfo(true)
        }}
      >
        <p className="whitespace-nowrap text-black font-medium text-sm px-1">
          {host && host.first_name + " " + host.last_name}
          {visitor && visitor.first_name + " " + visitor.last_name}
        </p>
        {visitor && (
          <button
            className="px-1"
            onClick={() => {
              setShowFloatingInfo(true)
              toggleVisitorEditMode()
            }}
          >
            <FontAwesomeIcon
              icon={faPencilAlt}
              size="xs"
              className="text-gray-500"
            />
          </button>
        )}
        <button
          className="px-1"
          onClick={() => {
            setVisitHost && setVisitHost(undefined)
            setShowFloatingInfo(false)
            visitor && setVisitorEditMode(false)
            visitor && dispatch(removeAdHocVisitor({ visitor, index }))
          }}
        >
          <FontAwesomeIcon icon={faXmark} className="text-gray-600" />
        </button>
      </div>
    </div>
  )
}
