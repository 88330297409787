import { useLogin, useTranslate } from "@pankod/refine-core"
import { LoginVariables } from "utilities/types"
import React, { useCallback, useState } from "react"
import { LOG, Logger } from "utilities/logger"
import ErrorText from "components/formMessage"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import { Logo } from "../Logo"

export const LoginForm = ({
  showDevContent,
  setContent
}: {
  showDevContent: boolean
  setContent: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [errorMessage, setErrorMessage] = useState("")
  const translate = useTranslate()
  const { mutate: login } = useLogin<LoginVariables>()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleLogin = () => {
    if (email && password) {
      localStorage.clear()
      sessionStorage.clear()
      login(
        { username: email, password: password },
        {
          onError: (error, _variables, _context) => {
            setErrorMessage(translate("pages.login.invalidCredentials"))
            void Logger().error(LOG.LOGIN, `${error.stack}`)
          }
        }
      )
    } else {
      setErrorMessage(translate("pages.login.enterCredentials"))
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && email && password) {
      handleLogin()
    }
  }

  const handleKeyPressCallback = useCallback(handleKeyPress, [email, password])

  return (
    <div className="flex justify-center items-center w-full min-h-full">
      <div className="w-full lg:w-96">
        <Logo className="h-10" />

        <h1 className="font-dmsans font-medium text-3xl mb-6 mt-8">
          {translate("pages.login.login")}
        </h1>

        <div className="space-y-6">
          <div>{errorMessage && ErrorText(errorMessage, true)}</div>

          <div>
            <label htmlFor="username" className="font-medium">
              {translate("pages.login.email")}
            </label>
            <input
              id="username"
              name="username"
              type="text"
              className="mt-2 block w-full rounded-md border-0 py-2 px-4 shadow-sm ring-1 ring-inset ring-one-gray-200 placeholder:text-one-gray-400 focus:ring-2 focus:ring-inset focus:ring-one-rose-400"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              onKeyDown={handleKeyPressCallback}
            />
          </div>

          <div>
            <div className="relative">
              <label htmlFor="password" className="font-medium">
                {translate("pages.login.password")}
              </label>
              <input
                id="password"
                type={passwordVisible ? "text" : "password"}
                name="password"
                className="mt-2 block w-full rounded-md border-0 py-2 pl-4 pr-12 shadow-sm ring-1 ring-inset ring-one-gray-200 placeholder:text-one-gray-400 focus:ring-2 focus:ring-inset focus:ring-one-rose-400"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                onKeyDown={handleKeyPressCallback}
              />
              <button
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="absolute flex top-1/2 right-0 transform -translate-y-1/2 items-center inset-y-0 pr-3 mt-4"
              >
                {passwordVisible ? (
                  <EyeSlashIcon className="h-6 w-6 hover:text-one-rose-400" />
                ) : (
                  <EyeIcon className="h-6 w-6 hover:text-one-rose-400" />
                )}
              </button>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => setContent("resetPassword")}
                className="text-one-rose-400 hover:text-one-rose-500"
              >
                {translate("pages.login.forgotPassword")}
              </button>
            </div>
          </div>
          <div>
            <button
              onClick={handleLogin}
              className="flex w-full justify-center rounded-lg bg-one-rose-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-one-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-400"
            >
              {translate("pages.login.login")}
            </button>
          </div>

          {showDevContent && (
            <div className="py-4">
              <div className="relative flex items-center w-full">
                <div className="w-full border-b border-gray-300"></div>
                <div className="absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center">
                  <div className="px-4 bg-white">
                    {translate("pages.login.or")}
                  </div>
                </div>
              </div>
            </div>
          )}

          {showDevContent && (
            <div>
              <button className="flex w-full justify-center rounded-lg bg-rose-900 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-rose-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-400">
                {translate("pages.login.loginSso")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
