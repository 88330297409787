import { Modal } from "components/Modal"
import { ChangePassword } from "../ChangePassword"
import { Dropdown } from "../Dropdown"
import { useCallback, useEffect, useState } from "react"
import { UserProfile } from "../UserProfile"
import {
  useCan,
  useOne,
  usePermissions,
  useRouterContext,
  useTranslate
} from "@pankod/refine-core"
import { IUserInfo } from "interfaces"
import { Feedback } from "../Feedback"
import { visitPermissionEnabled } from "utilities/permissionSelector"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { QuickNotificationModal } from "../modal/QuickNotificationModal"
import { Menu } from "@headlessui/react"
import { setIsDrawerOpen, setMode } from "reduxStore/reducers/drawerReducer"
import { useAppDispatch } from "reduxStore/store"
import { InputSearch } from "../globals/InputSearch"
import { DRAWER_MODES } from "../drawer/DrawerWrapper"
import posthog from "posthog-js"
import { WorkspacePicker } from "../sider/WorkspacePicker"
import { CompanyPicker } from "./CompanyPicker/CompanyPicker"
import {
  MessageSquareExclamationIcon,
  PlusIcon,
  SystamLogoIcon
} from "icons/icons"
import { Button } from "components/Button/Button"

export const Nav = () => {
  const { data: userInfo, isLoading } = useOne<IUserInfo>({
    resource: "users/me",
    id: ""
  })

  const userName = userInfo?.data?.person?.first_name || "Organization"

  const userLastName = userInfo?.data?.person?.last_name || "Owner"

  const firstInitial = userName.charAt(0).toUpperCase()
  const lastInitial = userLastName.charAt(0).toUpperCase()

  const userInitials = `${firstInitial}${lastInitial}`

  const [isWorkspaceDropdownOpen, setWorkspaceDropdown] = useState(false)
  const [showDropdown, setDropdown] = useState<boolean>(false)
  const [showChangePassword, setChangePassword] = useState<boolean>(false)
  const [showEditUserProfile, setEditUserProfile] = useState<boolean>(false)
  const [showGiveFeedback, setGiveFeedback] = useState<boolean>(false)
  const [showQuickNotificationModal, setShowQuickNotificationModal] =
    useState<boolean>(false)
  const [search, setSearch] = useState<string>("")

  const translate = useTranslate()
  const { data: permissionsData } = usePermissions<string>()
  const dispatch = useAppDispatch()
  const [showWorkspaceDropdown, setShowWorkspaceDropdown] = useState(false)
  const showDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(true))
  }, [dispatch])

  const setDrawerMode = useCallback(
    (mode: string) => {
      dispatch(setMode(mode))
    },
    [dispatch]
  )

  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  // Remove this boolean when functionality for search bar/other feature previews are done
  const [showFeaturePreview] = useState<boolean>(false)

  const { data: canAccessVisitModule } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  const handleClick = () => push("/")
  const handleHostNotification = () => {
    setShowQuickNotificationModal((prev) => !prev)
  }

  useEffect(() => {
    const orgId = userInfo?.data?.person?.organization_id
    if (orgId) {
      // Update organization id for posthog user
      posthog.capture("set_organizaton_id", {
        // $set_once would only set the value if it is not already set
        // It can't be used here, because organization_id is not permanent
        // as distinct user can use different users with different organization id's
        $set: { organization_id: orgId }
      })
    }
  }, [userInfo])

  if (isLoading) return null

  return (
    <div className=" border-b border-b-one-gray-100 bg-white fixed top-0 left-0 w-full z-[50]">
      {showQuickNotificationModal && (
        <QuickNotificationModal
          title="Host Notification"
          open={showQuickNotificationModal}
          onClose={() => setShowQuickNotificationModal(false)}
        />
      )}
      <Modal
        title={translate("dropdown.title.changepassword")}
        isOpen={showChangePassword}
        onClose={() => setChangePassword(false)}
      >
        <div className="flex justify-center items-center">
          <ChangePassword onClose={() => setChangePassword(false)} />
        </div>
      </Modal>

      <Modal
        title={translate("dropdown.title.editprofile")}
        isOpen={showEditUserProfile}
        onClose={() => setEditUserProfile(false)}
      >
        <UserProfile onClose={() => setEditUserProfile(false)} />
      </Modal>

      <Modal
        title={translate("dropdown.title.giveFeedback")}
        isOpen={showGiveFeedback}
        onClose={() => setGiveFeedback(false)}
      >
        <Feedback onClose={() => setGiveFeedback(false)} />
      </Modal>

      <div className="mx-100% w-full sm:px-4 lg:px-4 z-51">
        <div className="flex justify-between items-center h-14">
          <div className="flex items-center gap-4">
            <button onClick={handleClick} className="cursor-pointer">
              <SystamLogoIcon />
            </button>

            <div className="flex items-center">
              <CompanyPicker />
              /
              <WorkspacePicker
                showDropdown={showWorkspaceDropdown}
                setDropdown={setShowWorkspaceDropdown}
              />
            </div>
            {/* Remove this boolean check when functionality for search bar is done */}
            {showFeaturePreview && (
              <InputSearch
                search={search}
                setSearch={setSearch}
                placeholder="Search for visits, people, devices..."
              />
            )}
          </div>

          <div className="flex items-center space-x-2">
            {/* Info page button */}
            {showFeaturePreview && (
              <button
                className="flex bg-systam-lighter-blue transition-transform ease-out duration-200 hover:transform hover:scale-125 py-1 px-3 rounded-full items-center m-2 w-10 h-10"
                onClick={() => {
                  push(`/info`)
                }}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="text-black"
                />
              </button>
            )}

            {visitPermissionEnabled(permissionsData) &&
              canAccessVisitModule?.can && (
                <Button
                  text={translate("buttons.notifyHost")}
                  onClick={handleHostNotification}
                  variant="secondary"
                  icon={<MessageSquareExclamationIcon />}
                />
              )}

            {visitPermissionEnabled(permissionsData) &&
              canAccessVisitModule?.can && (
                <Menu as="div" className="relative">
                  <Menu.Button>
                    <Button
                      text={translate("buttons.addVisit")}
                      variant="secondary"
                      icon={<PlusIcon />}
                    />
                  </Menu.Button>
                  <Menu.Items
                    style={{ animation: "scaleIn 0.125s" }}
                    className="mt-2 absolute border border-one-gray-200/50 right-0 origin-top-right bg-white shadow-lg rounded-lg divide-y z-[60] divide-one-gray-100"
                  >
                    <div className="py-2">
                      <Menu.Item>
                        <button
                          onClick={() => {
                            setDrawerMode(DRAWER_MODES.NEW_VISIT)
                            showDrawer()
                          }}
                          className="flex items-center w-full px-4 py-2 text-one-gray-600 hover:bg-one-gray-100 whitespace-nowrap"
                        >
                          {translate("buttons.newVisit")}
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          onClick={() => {
                            setDrawerMode(DRAWER_MODES.EXPECTED_VISIT)
                            showDrawer()
                          }}
                          className="flex items-center w-full px-4 py-2 text-one-gray-600 hover:bg-one-gray-100 whitespace-nowrap"
                        >
                          {translate("buttons.expectedVisit")}
                        </button>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Menu>
              )}

            <div className="relative z-[60]">
              <button
                data-testid="navigation-bar-dropdown"
                onClick={() => {
                  setDropdown(!showDropdown)
                }}
                className="px-1 flex cursor-pointer flex-row items-center border border-gray rounded-full h-12 space-x-2"
              >
                <div className="relative flex justify-center items-center bg-white shadow-sm w-10 h-10 rounded-full">
                  {userInfo?.data?.picture ? (
                    <img
                      key={userInfo?.data?.picture}
                      src={`${userInfo?.data?.picture}?timestamp=${Date.now()}`}
                      className="absolute inset-0 object-cover h-full w-full rounded-full"
                      alt="user info"
                    />
                  ) : (
                    <div className="text-black text-lg font-normal">
                      {userInitials}
                    </div>
                  )}
                </div>
              </button>

              {showDropdown && (
                <Dropdown
                  setDropdown={setDropdown}
                  setChangePassword={setChangePassword}
                  setEditProfile={setEditUserProfile}
                  setGiveFeedback={setGiveFeedback}
                />
              )}
            </div>
          </div>
        </div>

        {(isWorkspaceDropdownOpen || showDropdown) && (
          <button
            className="absolute w-screen h-screen z-51 top-0 left-0"
            data-testid="navigation-bar-dropdown-close"
            onClick={() => {
              setWorkspaceDropdown(false)
              setDropdown(false)
            }}
          />
        )}
      </div>
    </div>
  )
}
