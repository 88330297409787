import React, { useEffect, useState } from "react"
import { useTranslate } from "@pankod/refine-core"
import {
  faUserGroup,
  faUser,
  faEnvelope,
  faPhone,
  faBriefcase
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { VisitorSearch } from "components/VisitorSearch"
import ErrorText from "components/formMessage"
import {
  IVisitorInfo,
  IMandatoryFields,
  IWorkspace,
  IVisitVisitor
} from "interfaces"
import { VisitorTag } from "modules/Visit/VisitorTag"
import {
  addAdHocVisitor,
  clearAdHocVisitors
} from "reduxStore/reducers/adHocVisitorReducer"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import { contactDetailType } from "modules/ContactDetails/contactDetailType"
import validators from "utilities/formValidator"
import { VisitGetVisitorsItem } from "types"
import { emptyFn } from "utilities/generic"

export interface SelectVisitorsProps {
  readonly workspace: IWorkspace
  readonly initialVisitors: readonly VisitGetVisitorsItem[]
  readonly visitorError: boolean
  readonly setVisitorError: React.Dispatch<React.SetStateAction<boolean>>
}

export function SelectVisitors({
  workspace,
  initialVisitors,
  visitorError,
  setVisitorError
}: SelectVisitorsProps): React.ReactElement {
  const translate = useTranslate()

  const dispatch = useAppDispatch()

  const [errors, setErrors] = useState<string[]>([])
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [company, setCompany] = useState("")
  const [visitorInfo, setVisitorInfo] = useState<IVisitorInfo>()
  const [showNewVisitorForm, setShowNewVisitorForm] = useState(false)
  const [visitorSearch, setVisitorSearch] = useState("")

  const visitors = useAppSelector((state) => state.adHocVisitor)
  const selectedWorkspaceId = workspace.id

  useEffect(() => {
    dispatch(clearAdHocVisitors())
    for (const visitor of initialVisitors) {
      handleAddExistingVisitor(toVisitorInfo(visitor, workspace))
    }
  }, [])

  useEffect(() => {
    handleAddExistingVisitor(visitorInfo)
  }, [visitorInfo])

  const handleAddExistingVisitor = (visitorInfo: IVisitorInfo | undefined) => {
    if (visitorInfo) {
      const email = visitorInfo.contact_details?.find(
        (contactDetail) => contactDetail.type === contactDetailType.email
      )?.value
      const phone = visitorInfo.contact_details?.find(
        (contactDetail) => contactDetail.type === contactDetailType.phone
      )?.value

      dispatch(
        addAdHocVisitor({
          state: "signed_in",
          first_name: visitorInfo.first_name ?? "",
          last_name: visitorInfo.last_name ?? "",
          email: email ?? "",
          phone: phone ?? "",
          company: visitorInfo?.company ?? "",
          visitorId: visitorInfo.id
        })
      )
    }

    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setCompany("")
    // setInfo("")
    // setVisitName("")
  }

  const handleAddVisitor = (adHocVisitor: IVisitVisitor) => {
    if (validate()) {
      showNewVisitorForm && setShowNewVisitorForm(false)

      setVisitorSearch("")
      dispatch(
        addAdHocVisitor({
          state: "signed_in",
          first_name: adHocVisitor.first_name,
          last_name: adHocVisitor.last_name,
          email: adHocVisitor.email,
          phone: adHocVisitor.phone,
          company: adHocVisitor.company
        })
      )

      setFirstName("")
      setLastName("")
      setEmail("")
      setPhone("")
      setCompany("")
      // setInfo("")
      // setVisitName("")
    }
  }

  const mandatoryFields: IMandatoryFields = {
    firstName: true,
    lastName: true,
    phone: false,
    email: false
  }

  function validate(): boolean {
    const validationErrors = validators.handleValidation(
      firstName,
      lastName,
      phone,
      email,
      mandatoryFields
    )
    // TODO: Add error banner for fields
    setErrors(validationErrors)
    return validationErrors["firstName"] ||
      validationErrors["lastName"] ||
      validationErrors["email"] ||
      validationErrors["phone"]
      ? false
      : true
  }

  return (
    <div className="mt-4 bg-gray-50">
      <div className="px-4  bg-white">
        <div className="flex flex-col">
          {selectedWorkspaceId && (
            <div>
              <div className="mb-4">
                <FontAwesomeIcon
                  size="lg"
                  icon={faUserGroup}
                  className="text-gray-400 mr-2"
                  style={{ width: "1.5rem" }}
                />
                <label>Visitor(s)</label>
              </div>
              <VisitorSearch
                workspaceId={selectedWorkspaceId}
                setVisitorId={emptyFn}
                setVisitorInfo={setVisitorInfo}
                showNewVisitorForm={showNewVisitorForm}
                setShowNewVisitorForm={setShowNewVisitorForm}
                setCreateFirstName={setFirstName}
                setCreateLastName={setLastName}
                setSearch={setVisitorSearch}
                search={visitorSearch}
                selectedVisitors={visitors}
                error={visitorError}
                setError={setVisitorError}
              />
            </div>
          )}
          <div>
            <div className="flex flex-col">
              {showNewVisitorForm && (
                <>
                  <div className="shadow-md rounded-md overflow-x-auto bg-white max-h-54 rounded-t-md mr-2">
                    <div className="pt-1 flex items-center">
                      <div className="flex flex-col gap-1 w-full">
                        <div className="flex flex-row items-center gap-2 px-2">
                          <FontAwesomeIcon
                            size="xs"
                            icon={faUser}
                            className="text-gray-400"
                            style={{ width: "1.5rem" }}
                          />
                          <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                            {translate("form.firstName")}
                            <div className="inline-block pl-1 scale-75">
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="circle"
                                className="svg-inline--fa fa-circle fa-2xs"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                color="orangered"
                              >
                                <path
                                  fill="currentColor"
                                  d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"
                                ></path>
                              </svg>
                            </div>
                          </label>
                          <input
                            className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                            value={firstName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setFirstName(e.target.value)}
                          />
                        </div>
                        {errors["firstName"] && ErrorText(errors["firstName"])}
                        <div className="flex flex-row items-center gap-2 px-2">
                          <FontAwesomeIcon
                            size="xs"
                            icon={faUser}
                            className="text-gray-400"
                            style={{ width: "1.5rem" }}
                          />
                          <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                            {translate("form.lastName")}
                            <div className="inline-block pl-1 scale-75">
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="circle"
                                className="svg-inline--fa fa-circle fa-2xs"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                color="orangered"
                              >
                                <path
                                  fill="currentColor"
                                  d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"
                                ></path>
                              </svg>
                            </div>
                          </label>
                          <input
                            className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                            value={lastName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setLastName(e.target.value)}
                          />
                        </div>
                        {errors["lastName"] && ErrorText(errors["lastName"])}
                        <div className="flex flex-row items-center gap-2 px-2">
                          <FontAwesomeIcon
                            size="xs"
                            icon={faEnvelope}
                            className="text-gray-400"
                            style={{ width: "1.5rem" }}
                          />
                          <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                            {translate("form.email")}
                          </label>
                          <input
                            className="text-black text-sm  placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                            value={email}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setEmail(e.target.value)}
                          />
                        </div>
                        {errors["email"] && ErrorText(errors["email"])}
                        <div className="flex flex-row items-center gap-2 px-2">
                          <FontAwesomeIcon
                            size="xs"
                            icon={faPhone}
                            className="text-gray-400"
                            style={{ width: "1.5rem" }}
                          />
                          <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                            {translate("form.phone")}
                          </label>
                          <input
                            className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                            value={phone}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setPhone(e.target.value)}
                          />
                        </div>
                        {errors["phone"] && ErrorText(errors["phone"])}
                        <div className="flex flex-row items-center gap-2 px-2">
                          <FontAwesomeIcon
                            size="xs"
                            icon={faBriefcase}
                            className="text-gray-400"
                            style={{ width: "1.5rem" }}
                          />
                          <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                            {translate("form.company")}
                          </label>
                          <input
                            className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                            value={company}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setCompany(e.target.value)}
                          />
                        </div>
                        <div
                          className="cursor-pointer pl-5 py-2 pt-1 mt-1 flex hover:bg-gray-100 items-center border-t border-gray-300 rounded-b-md"
                          onClick={() =>
                            handleAddVisitor({
                              state: "signed_in",
                              first_name: firstName,
                              last_name: lastName,
                              email: email,
                              phone: phone,
                              company: company
                            })
                          }
                        >
                          <p className="text-blue-500">
                            {`+ Add new visitor to visit`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {visitors && visitors.length > 1 ? (
                <div className="mt-2 pt-2">
                  <span className="truncate font-medium">
                    {visitors.length} visitors added for a group visit
                  </span>
                </div>
              ) : (
                <></>
              )}
              {visitors && (
                <div className="flex flex-wrap pt-2">
                  {visitors.map((adHocVisitor: IVisitVisitor, index) => {
                    return (
                      <div className="relative pb-1 pr-1" key={index}>
                        <VisitorTag visitor={adHocVisitor} index={index} />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function toVisitorInfo(
  input: VisitGetVisitorsItem,
  workspace: IWorkspace
): IVisitorInfo {
  return {
    id: input.visitor_id ?? 0,
    uuid: input.uuid,
    first_name: input.details?.first_name ?? "",
    last_name: input.details?.last_name ?? "",
    workspace: {
      id: workspace.id,
      organization: workspace.organization,
      name: workspace.name,
      uuid: workspace.uuid,
      created: workspace.created,
      updated: workspace.updated
    },
    contact_details: input.details?.contact_details,
    company: input.details?.company,
    picture: input.details?.picture,
    language: input.details?.language,
    created: input.created,
    updated: input.updated
  }
}
