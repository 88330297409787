import { memo } from "react"
import { SideMenuItem } from "./SideMenuItem"
import { SiderMenuItemsProps } from "./Sider"

export const SideBarMenuItemWrapper = memo(
  ({ title = "", children, loading }: SiderMenuItemsProps) => {
    if (loading) {
      return null
    }

    return (
      <div className="">
        <div className="flex-grow truncate ml-2 text-one-gray-600 flex items-center mb-2">
          {title}
        </div>
        {children.map(({ label, key, isLocked, ...otherProps }) => (
          <div key={key}>
            {title ? (
              <SideMenuItem label={label} isLocked={isLocked} {...otherProps} />
            ) : (
              <div className="mt-auto">
                <SideMenuItem
                  label={label}
                  isLocked={isLocked}
                  {...otherProps}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }
)

SideBarMenuItemWrapper.displayName = "SideBarMenuItemWrapper"
