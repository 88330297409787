import { useMenu, useRouterContext } from "@pankod/refine-core"
import { useDispatch } from "react-redux"
import { LOG, Logger } from "utilities/logger"
import { setDeviceSearch } from "reduxStore/reducers/deviceSearchReducer"
import { LockIcon } from "icons/icons"
import { SiderMenuItemsProps } from "./Sider"

export const SideMenuItem = ({
  name,
  route,
  icon,
  label,
  ...otherProps
}: SiderMenuItemsProps) => {
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const { selectedKey } = useMenu()
  const dispatch = useDispatch()

  const isSelected = route === selectedKey
  const {
    parentName,
    show,
    list,
    create,
    edit,
    canCreate,
    canEdit,
    canDelete,
    canShow,
    isLocked,
    ...domProps
  } = otherProps

  const handleClick = () => {
    if (route?.includes("devices") || route?.includes("displays")) {
      dispatch(setDeviceSearch(""))
    }
    void Logger().log(LOG.CHANGE_PAGE, route)
    push(route)
  }

  return (
    <button
      onClick={handleClick}
      className={`w-full flex items-center h-10 rounded-lg ${
        isSelected ? "bg-black text-white" : "text-black hover:bg-gray-200"
      }`}
      {...domProps}
    >
      <div
        className={`flex items-center justify-center w-6 h-6 ${
          isSelected ? "text-one-gray-300" : "text-one-gray-400"
        }`}
      >
        <div className="ml-2">{icon}</div>
      </div>
      <div className="flex-1 flex items-center justify-between ml-2">
        <span className={isSelected ? "text-white" : "text-black"}>
          {label}
        </span>
        {isLocked && (
          <div className="mr-2">
            <LockIcon />
          </div>
        )}
      </div>
    </button>
  )
}
