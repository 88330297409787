import {
  IResourceComponentsProps,
  usePermissions,
  useQueryClient,
  useRouterContext,
  useTranslate
} from "@pankod/refine-core"
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable
} from "@pankod/refine-react-table"
import { Table } from "components/table/table"
import { usePagination } from "hooks/usePagination"
import React, { useCallback, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import { RoomsResponse, SingleRoomResponse } from "types"
import { INF_SCROLL_QUERY_KEY_TYPES } from "utilities/types"
import { DRAWER_MODES } from "components/drawer/DrawerWrapper"
import { setIsDrawerOpen, setMode } from "reduxStore/reducers/drawerReducer"

import {
  CheckIcon,
  ChevronRightIcon,
  PlusIcon,
  XMarkIcon
} from "@heroicons/react/20/solid"
import { getResourceTypeIcon } from "utilities/icons"

export const SpacesList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate()
  const { data: permissionsData } = usePermissions<string>()
  const queryClient = useQueryClient()
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  useEffect(() => {
    // Remove hosts query if selected workspace is changed
    queryClient.removeQueries({ queryKey: [INF_SCROLL_QUERY_KEY_TYPES.ROOMS] })
  }, [selectedWorkspaceId])

  // TODO: Remove redirection when feature is complete and the flag is removed
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const generateIcon = (type?: string) => {
    const imgSrc = getResourceTypeIcon(type)

    return (
      <div className="border rounded-full">
        <img
          src={imgSrc}
          alt="calendarType"
          className="flex items-center m-2 justify-center w-6 h-6"
        />
      </div>
    )
  }

  const columnsMemoized = React.useMemo<ColumnDef<any, any>[]>(() => {
    return [
      {
        id: "room_name",
        header: "Name",
        cell: ({ row }) => {
          return <h1 className="font-medium">{row.original.name}</h1>
        }
      },
      {
        id: "resource_address",
        header: "Calendar resource address",
        cell: ({ row }) => {
          const { resources } = row.original as SingleRoomResponse
          if (resources != null && resources.length > 0) {
            return (
              <div className="flex flex-row items-center">
                <div className="mr-2">
                  {generateIcon(resources[0]?.type as string)}
                </div>
                <div className="truncate">{resources[0]?.address}</div>
              </div>
            )
          } else {
            return <div className="flex py-3 items-center">Not found</div>
          }
        }
      },
      {
        id: "centered_linked_devices",
        header: "Linked devices",
        cell: ({ row }) => {
          const { devices } = row.original as SingleRoomResponse
          return (
            <div className="flex justify-center">
              {devices != null && devices.length > 0 ? (
                <CheckIcon className="h-5 w-5 text-emerald-500" />
              ) : (
                <XMarkIcon className="h-5 w-5 text-red-500" />
              )}
            </div>
          )
        }
      },
      {
        id: "navbutton",
        header: "",
        cell: ({ row }) => {
          return (
            <button
              onClick={() => {
                push(`/spaces/${row.original.id}`)
              }}
              className="flex items-center justify-center w-8 h-8 rounded-full"
            >
              <ChevronRightIcon className="h-6 w-6 text-gray-500" />
            </button>
          )
        }
      }
    ]
  }, [permissionsData])

  const isAddButton =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("All.ReadWrite") ||
    permissionsData?.includes("Room.ReadWrite") ||
    permissionsData?.includes("Room.All")

  const [spaces, page, isLoading, fetchNextPage, hasNextPage, isFetching] =
    usePagination<RoomsResponse>(
      `/rooms`,
      "",
      `workspace_id=${selectedWorkspaceId}`,
      INF_SCROLL_QUERY_KEY_TYPES.ROOMS
    )

  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    data: spaces,
    columns: columnsMemoized,
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true
  })

  const dispatch = useAppDispatch()

  const showDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(true))
  }, [dispatch])

  const setDrawerMode = useCallback(
    (mode: string) => {
      dispatch(setMode(mode))
    },
    [dispatch]
  )

  return (
    <div className="container mx-auto pb-4">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-medium items-center text-3xl font-kanit my-5">
            Spaces
          </h1>
          {isAddButton && (
            <button
              key="add_space"
              className="bg-one-gray-950 px-4 items-center h-10 rounded-lg"
              onClick={() => {
                setDrawerMode(DRAWER_MODES.CREATE_SPACE)
                showDrawer()
              }}
            >
              <div className="flex flex-row items-center">
                <PlusIcon className="h-5 w-5 text-white mr-2" />
                <p className="text-white">
                  {translate("pages.spaces.addSpace")}
                </p>
              </div>
            </button>
          )}
        </div>
        <Table
          page={page}
          getAllColumns={getAllColumns}
          getHeaderGroups={getHeaderGroups}
          getRowModel={getRowModel}
          isLoading={isLoading}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          hideColumnsButton={true}
          disableShowNoEntries={true}
        />
        {!isLoading && spaces.length === 0 && (
          <div className="flex flex-col text-center pt-16">
            <h1 className="text-xl font-medium">
              {translate("pages.spaces.noSpacesFound")}
            </h1>
            <div className="text-one-gray-500">
              {translate("pages.spaces.addNewHint")}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
