import {
  faBriefcase,
  faCalendar,
  faEnvelope,
  faPhone,
  faUser,
  faUserGroup
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Disclosure } from "@headlessui/react"
import { useTranslate, useCreate, useOne } from "@pankod/refine-core"
import { useQueryClient } from "@tanstack/react-query"
import { HostSearchDropdown } from "components/dropdown/HostSearchDropdown"
import ErrorText from "components/formMessage"
import useSendEmail, {
  IEmailNotificationMessageRequest
} from "hooks/useSendEmail"
import useSendSms from "hooks/useSendSms"
import {
  IHost_from_list,
  IMandatoryFields,
  IVisitVisitor,
  IVisitorInfo,
  WorkSpaceVisitTypes,
  WorkspaceSetting
} from "interfaces"
import { contactDetailType } from "modules/ContactDetails/contactDetailType"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react"
import { ClipLoader } from "react-spinners"
import { setIsDrawerOpen } from "reduxStore/reducers/drawerReducer"
import {
  addAdHocVisitor,
  clearAdHocVisitors
} from "reduxStore/reducers/adHocVisitorReducer"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import { SCOPE_ID, USER_ID_KEY } from "utilities/constants"
import validators from "utilities/formValidator"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { INF_SCROLL_QUERY_KEY_TYPES } from "utilities/types"
import { DateTime } from "luxon"
import { DateTimeInput } from "components/globals/DateTimeInput"
import { VisitorSearch } from "components/VisitorSearch"
import { HostTag } from "modules/Visit/HostTag"
import { VisitorTag } from "modules/Visit/VisitorTag"
import { VisitTimePicker } from "modules/Visit/VisitTimePicker"
import { DRAWER_MODES } from "components/drawer/DrawerWrapper"
import { createAddVisitRequest } from "modules/Visit/visitDataTransformations"
import {
  VisitorState,
  getNotificationMessageTemplateTypeName
} from "modules/Visit/Notification/visitNotificationMessageType"
import { handleAxiosError } from "modules/Visit/addVisitErrorHandler"
import { VisitTypeSelector } from "./ui/VisitTypeSelector"

type CreateVisitProps = {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
}

export const CreateVisit = (props: CreateVisitProps) => {
  const translate = useTranslate()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const [errors, setErrors] = useState<string[]>([])
  const [isError, setIsError] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [company, setCompany] = useState("")
  const [organizationId, setOrganizationId] = useState<number>()
  const [visitName, setVisitName] = useState<string>("")
  const [info, setInfo] = useState<string>("")
  const [visitHost, setVisitHost] = useState<IHost_from_list>()
  const [visitorId, setVisitorId] = useState<number>()
  const [visitorInfo, setVisitorInfo] = useState<IVisitorInfo>()
  const [showNewVisitorForm, setShowNewVisitorForm] = useState(false)
  const [visitorSearch, setVisitorSearch] = useState("")
  const [startDate, setStartDate] = useState<string | undefined | null>(
    DateTime.local().toISODate()
  )
  const [startTime, setStartTime] = useState<string | undefined | null>(null)

  const [visitorError, setVisitorError] = useState(false)
  const [hostError, setHostError] = useState(false)

  const drawerMode = useAppSelector((state) => state.drawer.mode)
  const [visitorState, setVisitorState] = useState<VisitorState | null>(null)
  const [visitTypeId, setVisitTypeId] = useState<number | null>(null)

  const visitors = useAppSelector((state) => state.adHocVisitor)
  const scopeId = localStorage.getItem(SCOPE_ID)
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  const { mutateAsync: addVisitAsync } = useCreate()
  const { mutateAsync: sendEmailAsync } = useSendEmail()
  const { mutateAsync: sendSmsAsync } = useSendSms()
  const { data: sendEmailWorkspaceSettingData } = useOne<WorkspaceSetting>({
    resource: `workspaces/${selectedWorkspaceId}/settings/sendemail`,
    id: ""
  })
  const { data: sendSmsWorkspaceSettingData } = useOne<WorkspaceSetting>({
    resource: `workspaces/${selectedWorkspaceId}/settings/sendsms`,
    id: ""
  })
  const { data: sendEmailInvitationWorkspaceSettingData } =
    useOne<WorkspaceSetting>({
      resource: `workspaces/${selectedWorkspaceId}/settings/sendexpectedemail`,
      id: ""
    })
  const { data: sendSmsInvitationWorkspaceSettingData } =
    useOne<WorkspaceSetting>({
      resource: `workspaces/${selectedWorkspaceId}/settings/sendexpectedsms`,
      id: ""
    })

  const { data: workspaceVisitTypes, isLoading } = useOne<WorkSpaceVisitTypes>({
    resource: `workspaces/${selectedWorkspaceId}/visit-types`,
    id: ""
  })

  const visitTypes = (workspaceVisitTypes?.data.visit_types ?? []).filter(
    (visitType) => visitType.is_enabled
  )

  const isVisitTypeMultiple = Array.isArray(visitTypes) && visitTypes.length > 1

  const hideDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(false))
  }, [dispatch])

  useEffect(() => {
    const visitorStateMap = {
      [DRAWER_MODES.NEW_VISIT]: VisitorState.SIGNED_IN,
      [DRAWER_MODES.EXPECTED_VISIT]: VisitorState.EXPECTED
    }
    setVisitorState(visitorStateMap[drawerMode] as VisitorState)
  }, [drawerMode])

  useEffect(() => {
    dispatch(clearAdHocVisitors())
  }, [])

  useEffect(() => {
    handleAddExistingVisitor()
  }, [visitorInfo])

  const handleAddExistingVisitor = () => {
    if (visitorInfo) {
      // Prevent duplicates
      if (visitors.find((visitor) => visitor.visitorId === visitorInfo.id)) {
        return
      }
      const email = visitorInfo.contact_details?.find(
        (contactDetail) => contactDetail.type === contactDetailType.email
      )?.value
      const phone = visitorInfo.contact_details?.find(
        (contactDetail) => contactDetail.type === contactDetailType.phone
      )?.value

      dispatch(
        addAdHocVisitor({
          state: "signed_in",
          first_name: visitorInfo.first_name ?? "",
          last_name: visitorInfo.last_name ?? "",
          email: email ?? "",
          phone: phone ?? "",
          company: visitorInfo?.company ?? "",
          visitorId: visitorId
        })
      )
    }

    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setCompany("")
    setInfo("")
    setVisitName("")
  }

  const handleAddVisitor = (adHocVisitor: IVisitVisitor) => {
    if (validate()) {
      showNewVisitorForm && setShowNewVisitorForm(false)

      setVisitorSearch("")
      dispatch(
        addAdHocVisitor({
          state: "signed_in",
          first_name: adHocVisitor.first_name,
          last_name: adHocVisitor.last_name,
          email: adHocVisitor.email,
          phone: adHocVisitor.phone,
          company: adHocVisitor.company
        })
      )

      setFirstName("")
      setLastName("")
      setEmail("")
      setPhone("")
      setCompany("")
      setInfo("")
      setVisitName("")
    }
  }

  const handleCreateVisit = async (visitorState: VisitorState) => {
    try {
      // Set errors
      if (visitHost === undefined) {
        setHostError(true)
      }
      if (visitors.length === 0) {
        setVisitorError(true)
      }
      if (visitTypeId === null) {
        setIsError(true)
      }
      if (visitors.length > 0 && visitHost) {
        setLoading(true)

        const addVisitResponse = await addVisitAsync({
          resource: `visits/`,
          values: createAddVisitRequest({
            organizationId: scopeId,
            visitors,
            workspaceId: selectedWorkspaceId,
            visitorState,
            visitHostId: visitHost?.id,
            startDate,
            startTime,
            visitName,
            info,
            visitTypeId
          })
        })

        customToast.success(translate("notifications.createVisit"))

        if (
          sendEmailWorkspaceSettingData?.data.value === "true" &&
          (visitorState === VisitorState.SIGNED_IN ||
            (visitorState === VisitorState.EXPECTED &&
              sendEmailInvitationWorkspaceSettingData?.data.value === "true"))
        ) {
          try {
            const emailPayload: IEmailNotificationMessageRequest = {
              userId: localStorage.getItem(USER_ID_KEY) || "",
              visitId: addVisitResponse.data.id as number,
              visitors: visitors,
              visitorName: `${visitors[0]?.first_name ?? firstName} ${
                visitors[0]?.last_name ?? lastName
              }`,
              visitorCompanyName: company,
              visitorPhone: phone,
              visitorEmail: email,
              hostId: visitHost?.id,
              hostName: `${visitHost?.person?.first_name} ${visitHost?.person?.last_name}`,
              hostEmail: visitHost?.person?.email,
              organizationId: organizationId,
              template: getNotificationMessageTemplateTypeName(
                visitorState,
                addVisitResponse?.data?.visitors_count as number
              )
            }

            await sendEmailAsync({
              request: emailPayload
            })

            customToast.success(translate("notifications.emailSentSuccess"))
          } catch (error: any) {
            void Logger().error(LOG.SEND_EMAIL_ERROR, `${error.stack}`)
            customToast.error(translate("notifications.emailError"))
          }
        }

        if (
          sendSmsWorkspaceSettingData?.data.value === "true" &&
          (visitorState === VisitorState.SIGNED_IN ||
            (visitorState === VisitorState.EXPECTED &&
              sendSmsInvitationWorkspaceSettingData?.data.value === "true"))
        ) {
          try {
            const smsPayload = {
              userId: localStorage.getItem(USER_ID_KEY) || "",
              visitId: addVisitResponse.data.id as number,
              visitors: visitors,
              visitorName: `${visitors[0]?.first_name ?? firstName} ${
                visitors[0]?.last_name ?? lastName
              }`,
              visitorCompanyName: company,
              visitorPhone: phone,
              hostId: visitHost?.id,
              destination: visitHost?.person?.phone,
              userData: process.env.REACT_APP_MESSAGING_API_PROJECT_ID,
              organizationId: organizationId,
              template: getNotificationMessageTemplateTypeName(
                visitorState,
                addVisitResponse?.data?.visitors_count as number
              )
            }

            await sendSmsAsync({
              request: smsPayload
            })

            customToast.success(translate("notifications.smsSentSuccess"))
          } catch (error: any) {
            void Logger().error(LOG.SEND_SMS_ERROR, `${error.stack}`)
            customToast.error(translate("notifications.smsError"))
          }
        }

        void queryClient.resetQueries({
          queryKey: [INF_SCROLL_QUERY_KEY_TYPES.VISITS]
        })

        hideDrawer()
      }
    } catch (error: any) {
      handleAxiosError(error, selectedWorkspaceId)
    } finally {
      setLoading(false)
    }
  }
  const handleSelect = (typeId: number) => () => {
    setVisitTypeId(typeId)
    setIsError(false)
  }
  // TODO: Set these values somewhere more global
  const mandatoryFields: IMandatoryFields = {
    firstName: true,
    lastName: true,
    phone: false,
    email: false
  }

  /**
   * Validate fields
   * @returns True if no errors
   */
  function validate(): boolean {
    const validationErrors = validators.handleValidation(
      firstName,
      lastName,
      phone,
      email,
      mandatoryFields
    )
    setErrors(validationErrors)
    return !Object.values(validationErrors).some((error) => error)
  }

  useEffect(() => {
    if (!isVisitTypeMultiple && visitTypes.length === 1) {
      setVisitTypeId(visitTypes[0]?.id || null)
    }
  }, [visitTypes, isVisitTypeMultiple])

  if (isLoading) return <p>Loading visit types...</p>
  return (
    <div className="flex-auto flex flex-col mt-8">
      <div className="flex-auto min-w-144 min-h-0 max-w-form max-w-md">
        {drawerMode === DRAWER_MODES.EXPECTED_VISIT && (
          <div className="mt-0 bg-gray-50">
            <div className="px-4 pb-2 bg-white">
              {isVisitTypeMultiple && (
                <VisitTypeSelector
                  visitTypes={visitTypes}
                  onClick={handleSelect}
                  visitTypeId={visitTypeId}
                  isError={isError}
                />
              )}
              <div className="mb-4">
                <FontAwesomeIcon
                  size="lg"
                  icon={faCalendar}
                  className="text-gray-400 mr-2"
                  style={{ width: "1.5rem" }}
                />
                <label>{translate("buttons.dateOfVisit")}</label>
              </div>
              <div className="flex flex-row space-x-4">
                <div className="flex-shrink">
                  <DateTimeInput
                    title="Start date"
                    type="date"
                    value={startDate}
                    min={DateTime.local().toISODate()}
                    onChange={(e) => {
                      setStartDate(e as string)
                    }}
                  />
                </div>
                <div className="flex-shrink">
                  <VisitTimePicker
                    title="Start time"
                    startDate={startDate}
                    initialVisitTime={startTime}
                    onChange={(e) => {
                      setStartTime(e)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 bg-gray-50">
          <div className="px-4  bg-white">
            <div className="flex flex-col">
              {selectedWorkspaceId && (
                <div>
                  <div className="mb-4">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faUserGroup}
                      className="text-gray-400 mr-2"
                      style={{ width: "1.5rem" }}
                    />
                    <label>Visitor(s)</label>
                  </div>
                  <VisitorSearch
                    workspaceId={parseInt(selectedWorkspaceId)}
                    setVisitorId={setVisitorId}
                    setVisitorInfo={setVisitorInfo}
                    showNewVisitorForm={showNewVisitorForm}
                    setShowNewVisitorForm={setShowNewVisitorForm}
                    setCreateFirstName={setFirstName}
                    setCreateLastName={setLastName}
                    setSearch={setVisitorSearch}
                    search={visitorSearch}
                    selectedVisitors={visitors}
                    error={visitorError}
                    setError={setVisitorError}
                  />
                </div>
              )}
              <div>
                <div className="flex flex-col">
                  {showNewVisitorForm && (
                    <>
                      <div className="shadow-md rounded-md overflow-x-auto bg-white max-h-54 rounded-t-md mr-2">
                        <div className="pt-1 flex items-center">
                          <div className="flex flex-col gap-1 w-full">
                            <div className="flex flex-row items-center gap-2 px-2">
                              <FontAwesomeIcon
                                size="xs"
                                icon={faUser}
                                className="text-gray-400"
                                style={{ width: "1.5rem" }}
                              />
                              <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                                {translate("form.firstName")}
                                <div className="inline-block pl-1 scale-75">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="circle"
                                    className="svg-inline--fa fa-circle fa-2xs"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    color="orangered"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"
                                    ></path>
                                  </svg>
                                </div>
                              </label>
                              <input
                                className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                                value={firstName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setFirstName(e.target.value)}
                              />
                            </div>
                            {errors["firstName"] &&
                              ErrorText(errors["firstName"])}
                            <div className="flex flex-row items-center gap-2 px-2">
                              <FontAwesomeIcon
                                size="xs"
                                icon={faUser}
                                className="text-gray-400"
                                style={{ width: "1.5rem" }}
                              />
                              <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                                {translate("form.lastName")}
                                <div className="inline-block pl-1 scale-75">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="circle"
                                    className="svg-inline--fa fa-circle fa-2xs"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    color="orangered"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"
                                    ></path>
                                  </svg>
                                </div>
                              </label>
                              <input
                                className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                                value={lastName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setLastName(e.target.value)}
                              />
                            </div>
                            {errors["lastName"] &&
                              ErrorText(errors["lastName"])}
                            <div className="flex flex-row items-center gap-2 px-2">
                              <FontAwesomeIcon
                                size="xs"
                                icon={faEnvelope}
                                className="text-gray-400"
                                style={{ width: "1.5rem" }}
                              />
                              <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                                {translate("form.email")}
                              </label>
                              <input
                                className="text-black text-sm  placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                                value={email}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setEmail(e.target.value)}
                              />
                            </div>
                            {errors["email"] && ErrorText(errors["email"])}
                            <div className="flex flex-row items-center gap-2 px-2">
                              <FontAwesomeIcon
                                size="xs"
                                icon={faPhone}
                                className="text-gray-400"
                                style={{ width: "1.5rem" }}
                              />
                              <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                                {translate("form.phone")}
                              </label>
                              <input
                                className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                                value={phone}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setPhone(e.target.value)}
                              />
                            </div>
                            {errors["phone"] && ErrorText(errors["phone"])}
                            <div className="flex flex-row items-center gap-2 px-2">
                              <FontAwesomeIcon
                                size="xs"
                                icon={faBriefcase}
                                className="text-gray-400"
                                style={{ width: "1.5rem" }}
                              />
                              <label className="whitespace-nowrap text-sm sm:text-base tracking-wide text-gray-600 w-2/5">
                                {translate("form.company")}
                              </label>
                              <input
                                className="text-black text-sm placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-1 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                                value={company}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setCompany(e.target.value)}
                              />
                            </div>
                            <div
                              className="cursor-pointer pl-5 py-2 pt-1 mt-1 flex hover:bg-gray-100 items-center border-t border-gray-300 rounded-b-md"
                              onClick={() =>
                                handleAddVisitor({
                                  state: "signed_in",
                                  first_name: firstName,
                                  last_name: lastName,
                                  email: email,
                                  phone: phone,
                                  company: company
                                })
                              }
                            >
                              {loading ? (
                                <ClipLoader
                                  loading
                                  color="#fff"
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              ) : (
                                <p className="text-blue-500">
                                  {`+ Add new visitor to visit`}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {visitors && visitors.length > 1 ? (
                    <div className="mt-2 pt-2">
                      <span className="truncate font-medium">
                        {visitors.length} visitors added for a group visit
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {visitors && (
                    <div className="flex flex-wrap pt-2">
                      {visitors.map((adHocVisitor: IVisitVisitor, index) => {
                        return (
                          <div className="relative pb-1 pr-1" key={index}>
                            <VisitorTag visitor={adHocVisitor} index={index} />
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50">
          <div className="px-4 bg-white">
            <HostSearchDropdown
              setOrganizationId={setOrganizationId}
              setVisitHost={setVisitHost}
              visitHost={visitHost}
              error={hostError}
              setError={setHostError}
            />
            {visitHost && (
              <HostTag visitHost={visitHost} setVisitHost={setVisitHost} />
            )}
          </div>
        </div>
        <Disclosure as="div" className="mt-4 mb-4 bg-gray-50">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between items-center rounded-md px-4 py-2 text-left text-base font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>{translate("form.extraInformation")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-4 w-4 text-systam-dark`}
                >
                  <path d="M24 12L16 22 8 12z"></path>
                </svg>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 bg-white">
                <label className="mb-1 text-base sm:text-base tracking-wide text-gray-600">{`${translate(
                  "form.visitName"
                )} ${translate("form.optional")}`}</label>
                <input
                  className="text-base sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-2 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                  value={visitName}
                  placeholder={translate("form.visitNamePlaceholder")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setVisitName(e.target.value)
                  }
                />
                <label className="mb-1 text-base sm:text-base tracking-wide text-gray-600">{`${translate(
                  "form.info"
                )} ${translate("form.optional")}`}</label>
                <input
                  className="text-base sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-2 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                  value={info}
                  placeholder={translate("form.visitInfoPlaceholder")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setInfo(e.target.value)
                  }
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div className="bottom-0 right-0 left-0 flex items-center justify-between px-4 space-x-4">
        <button
          onClick={hideDrawer}
          className="items-center justify-center focus:outline-none text-base sm:text-base font-medium bg-gray-200 hover:bg-gray-300 rounded py-2 w-1/5 transition duration-150 ease-in border border-transparent text-gray-600 px-5 mr-2"
        >
          {translate("buttons.cancel")}
        </button>
        <button
          onClick={() => {
            if (visitorState !== null) {
              void handleCreateVisit(visitorState)
            }
          }}
          className="uppercase items-center justify-center text-white text-base sm:text-base bg-systam-blue hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
        >
          {loading ? (
            <ClipLoader
              loading
              color="#fff"
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            translate(
              drawerMode === DRAWER_MODES.EXPECTED_VISIT
                ? "buttons.preregisterVisit"
                : "buttons.createVisit"
            )
          )}
        </button>
      </div>
    </div>
  )
}
