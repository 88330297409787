import { useCustom } from "@pankod/refine-core"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useAppSelector } from "reduxStore/store"
import { Room, RoomsResponse } from "types/pretty-api-types"
import { DropdownOptions } from "./DropdownOptions"
import { DropdownButton } from "./DropdownButton"

interface SpacesDropdownProps {
  selectedRoomId: number | undefined
  setSelectedRoomId: Dispatch<SetStateAction<number | undefined>>
}
// TODO replace with chadCn UI Dropdown Menu component to avoid using event listeners inside
export const SpacesDropdown = ({
  selectedRoomId,
  setSelectedRoomId
}: SpacesDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRoomName, setSelectedRoomName] = useState("")
  const ref = useRef<HTMLDivElement>(null)

  const { data: spacesData } = useCustom<RoomsResponse>({
    url: "rooms",
    method: "get"
  })

  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  useEffect(() => {
    if (selectedRoomName === "") {
      const name = spacesData?.data.items?.find(
        (room) => room.id === selectedRoomId
      )?.name
      setSelectedRoomName(name ?? "")
    }
  }, [spacesData, selectedRoomId, selectedRoomName])

  const handleRoomSelect = (room: Room) => {
    const roomName: string = room.name
    const roomId: number = room.id

    setSelectedRoomName(roomName)
    setSelectedRoomId(roomId)
    setIsOpen(false)
  }

  const filteredRooms =
    spacesData?.data.items?.filter((room) => {
      return Number(room.workspace_id) === Number(selectedWorkspaceId)
    }) ?? []

  return (
    <div className="flex flex-col relative" ref={ref}>
      <DropdownButton
        selectedRoomName={selectedRoomName}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />

      {spacesData?.data.items && isOpen && (
        <DropdownOptions rooms={filteredRooms} onSelect={handleRoomSelect} />
      )}
    </div>
  )
}
