import {
  useTranslate,
  useCustomMutation,
  usePermissions
} from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { useEffect, useState } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import {
  useTimezoneSelect,
  allTimezones,
  ITimezoneOption
} from "react-timezone-select"

const labelStyle = "original"
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt"
}

export const SettingTimeZone = ({
  settingsData: {
    value,
    endpoint,
    setting_metadata: { description, key }
  }
}: {
  settingsData: ISettingEntity
}) => {
  const translate = useTranslate()
  const { mutate } = useCustomMutation()
  const workspace = localStorage.getItem(SELECTED_WORKSPACE) ?? ""
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")

  const [timezone, setTimezone] = useState<ITimezoneOption>()

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones
  })

  useEffect(() => {
    const parsed = parseTimezone(value)
    if (parsed) {
      setTimezone(parseTimezone(value))
    } else if (value === "UTC") {
      setTimezone(parseTimezone("Etc/UTC"))
    }
  }, [value])

  function changeTimezone(timezone: ITimezoneOption) {
    const val = timezone.value === "Etc/GMT" ? "UTC" : timezone.value

    mutate(
      {
        url: endpoint.replace("{id}", workspace),
        method: "put",
        values: { value: val }
      },
      {
        onSuccess: (_variables, _context) => {
          setTimezone(timezone)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().log(LOG.EDIT_SETTING, key)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(
            LOG.EDIT_SETTING,
            `${key}: ${JSON.stringify(_error)}`
          )
        }
      }
    )
  }

  return (
    <div className="flex flex-row flex-1 mt-2 mb-4 mr-2 justify-between items-center">
      <div className="flex flex-col gap-2 justify-between mb-1">
        <h1 className="font-medium text-md">
          {translate(`pages.settings.${key}`)}
        </h1>
        <select
          disabled={!canEdit}
          className="flex w-full py-2 rounded-md pl-2 !border !border-gray-300"
          onChange={(e) => changeTimezone(parseTimezone(e.currentTarget.value))}
          value={timezone?.value}
        >
          {options.map((option) => (
            <option key={`key_${option.value}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <p className="text-gray-500 text-sm whitespace-pre-wrap">
          {translate(description)}
        </p>
      </div>
    </div>
  )
}
