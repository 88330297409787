import { VisitTypeItem } from "./VisitTypeItem"
import { ExclamationMarkIcon } from "icons/icons"
import { VisitTypeSelectorProps } from "interfaces"
import { useTranslate } from "@pankod/refine-core"

export const VisitTypeSelector: React.FC<VisitTypeSelectorProps> = ({
  visitTypes,
  onClick,
  visitTypeId,
  isError
}) => {
  const t = useTranslate()

  return (
    <div className="flex flex-col gap-2 mb-2">
      <span className="text-[14px] font-medium leading-[20px] tracking-[-0.28px] text-title-black">
        Visit type
      </span>
      <div className="flex gap-4">
        {visitTypes.map(({ id, name }) => (
          <VisitTypeItem
            id={id}
            key={id}
            value={name}
            isActive={visitTypeId === id}
            onClick={onClick}
            isError={isError && visitTypeId === id}
          />
        ))}
      </div>

      <div className="h-6 flex gap-2 items-center">
        {isError ? (
          <>
            <ExclamationMarkIcon />
            <p className="text-red-500">
              {t("form.validators.emptyVisitType")}
            </p>
          </>
        ) : null}
      </div>
    </div>
  )
}
