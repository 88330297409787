import {
  useMenu,
  usePermissions,
  useCan,
  IResourceItem
} from "@pankod/refine-core"
import { getPermittedMenuItems } from "utilities/permissionSelector"
import { SideBarMenuItemWrapper } from "./SideBarMenuItemWrapper"
import { SideBarFooter } from "./SiderFooter"
import { useCallback, useMemo } from "react"

export interface SiderMenuItemsProps extends Partial<IResourceItem> {
  children: SiderMenuItemsProps[]
  isLocked: boolean
  loading?: boolean
  title?: string
}

export const Sider: React.FC = () => {
  const { menuItems } = useMenu()
  const { data: permissionsData } = usePermissions<string>()

  const { data: canAccessVisitModule, isLoading } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  const { data: canAccessRoomsModule } = useCan({
    resource: "rooms",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["rooms"]
        }
      }
    }
  })

  const moduleAccessMap = {
    visit: canAccessVisitModule?.can,
    rooms: canAccessRoomsModule?.can
  }

  const newMenuItems = [...getPermittedMenuItems(permissionsData, menuItems)]

  const result = newMenuItems.reduce(
    (acc: SiderMenuItemsProps[], currentItem) => {
      const { label, key, name, icon, options, ...otherProps } = currentItem

      if (!name) return acc

      const moduleName = options?.modules?.[0] as string
      const isLocked = moduleName ? !moduleAccessMap[moduleName] : false

      if (moduleName) {
        const menuItem = {
          label: moduleName.charAt(0).toUpperCase() + moduleName.slice(1),
          name: moduleName,
          isLocked,
          children: []
        } as SiderMenuItemsProps

        const existingItem = acc.find((item) => item.name === moduleName)

        if (existingItem) {
          existingItem.children.push({
            label,
            key: key || "",
            name,
            icon,
            isLocked,
            ...otherProps
          } as SiderMenuItemsProps)
        } else {
          menuItem.children.push({
            label,
            key: key || "",
            name,
            icon,
            isLocked,
            ...otherProps
          } as SiderMenuItemsProps)
          acc.push(menuItem)
        }
      } else {
        acc.push({
          name,
          isLocked,
          children: [
            { key, name, label, icon, ...otherProps }
          ] as SiderMenuItemsProps[]
        } as SiderMenuItemsProps)
      }

      return acc
    },
    [] as SiderMenuItemsProps[]
  )

  const [itemsWithLabel, itemsWithoutLabel] = useMemo(
    () =>
      result.reduce<[SiderMenuItemsProps[], SiderMenuItemsProps[]]>(
        (acc, item) => {
          acc[item.label ? 0 : 1].push(item)
          return acc
        },
        [[], []]
      ),
    [result]
  )

  const renderMenuItems = useCallback(
    (items: SiderMenuItemsProps[]) =>
      items.length > 0 &&
      items.map(({ label, children, key, isLocked, name }) => (
        <SideBarMenuItemWrapper
          title={label ?? ""}
          key={key ?? name}
          children={children}
          loading={isLoading}
          isLocked={isLocked}
        />
      )),
    [isLoading]
  )

  return (
    <div className="flex flex-col min-w-68 bg-one-gray-100 fixed top-13 left-0 h-[calc(100vh-3.25rem)]">
      <div className="flex flex-col flex-grow px-4 py-4 gap-6">
        {renderMenuItems(itemsWithLabel)}
      </div>
      <div className="flex flex-col px-4">
        {renderMenuItems(itemsWithoutLabel)}
      </div>
      <div className="flex items-center ml-6 h-20">
        <SideBarFooter />
      </div>
    </div>
  )
}
