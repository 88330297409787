import { convertToNumber } from "utilities/string"
import { VisitorState } from "./Notification/visitNotificationMessageType"
import { Email, Phone, VisitorDataModel } from "./VisitorDataModel"
import { AddVisitRequest } from "./addVisit"
import { formatVisitDateTime } from "./visitDateTime"

type IVisitor = {
  state: string
  first_name: string
  last_name: string
  email: string
  phone: string
  company: string
  visitorId?: number
}

type AddVisitRequestParams = {
  organizationId: string | null
  visitors: IVisitor[]
  workspaceId: string | null
  visitorState: VisitorState
  visitHostId: number | undefined
  startDate?: string | null
  endTime?: string | null
  visitName?: string
  info?: string
  endDate?: string | null
  startTime?: string | null
  visitTypeId: number | null
}

const createContactDetail = (
  type: string,
  value: string
): Email | Phone | undefined => {
  return value
    ? {
        primary: true,
        notifications: true,
        value: value
      }
    : undefined
}

const createVisitorData = (
  visitor: IVisitor,
  selectedWorkspaceId: number,
  visitorState: string
): VisitorDataModel => {
  const emails = visitor.email
    ? [createContactDetail("email", visitor.email)].filter(
        (item): item is Email => item !== undefined
      )
    : []
  const phones = visitor.phone
    ? [createContactDetail("phone", visitor.phone)].filter(
        (item): item is Phone => item !== undefined
      )
    : []

  return {
    state: visitorState,
    visitor_data: {
      company: visitor.company,
      first_name: visitor.first_name,
      last_name: visitor.last_name,
      email: emails.length > 0 ? emails : undefined,
      phone: phones.length > 0 ? phones : undefined,
      workspace_id: selectedWorkspaceId
    }
  }
}

const createVisitorPayload = (
  visitors: IVisitor[],
  selectedWorkspaceId: number | undefined,
  visitorState: string | undefined
): Array<VisitorDataModel> => {
  if (selectedWorkspaceId === undefined || visitorState === undefined) {
    throw new Error(
      "Invalid arguments: 'selectedWorkspaceId' and 'visitorState' cannot be undefined"
    )
  }

  return visitors.map((visitor) =>
    visitor.visitorId
      ? { state: visitorState, visitor_id: visitor.visitorId }
      : createVisitorData(visitor, selectedWorkspaceId, visitorState)
  )
}

export const createAddVisitRequest = ({
  organizationId,
  visitors,
  workspaceId,
  visitorState,
  visitHostId,
  startDate,
  startTime,
  endDate,
  endTime,
  visitName,
  info,
  visitTypeId
}: AddVisitRequestParams): AddVisitRequest => {
  return {
    name: visitName,
    info: info,
    visitors: createVisitorPayload(
      visitors,
      convertToNumber(workspaceId),
      visitorState
    ),
    hosts: [
      {
        organizer: true,
        host_id: Number(visitHostId)
      }
    ],
    start_time:
      visitorState === VisitorState.SIGNED_IN
        ? undefined
        : formatVisitDateTime(startDate, startTime),
    end_time:
      visitorState === VisitorState.SIGNED_IN
        ? undefined
        : formatVisitDateTime(endDate, endTime),
    workspace_id: Number(workspaceId),
    visit_type_id: Number(visitTypeId)
  }
}
