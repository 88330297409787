import { SelectedIcon } from "icons/icons"
import { VisitTypeItemProps } from "interfaces"

export const VisitTypeItem = ({
  id,
  value,
  isActive,
  isError,
  onClick
}: VisitTypeItemProps) => {
  return (
    <button
      onClick={onClick(id)}
      className={`flex justify-between items-center h-12 w-80 px-4 border rounded-[12px] bg-white cursor-pointer
        ${isActive ? "border-[#2F2F2F]" : "border-gray-300"}
        ${isError ? "border-red-500" : ""}
        shadow-[0px_1px_1px_rgba(30,30,30,0.05)]
        transition duration-200
        focus:outline-none`}
    >
      <span className="text-[14px] font-medium leading-[20px] tracking-[-0.28px] text-title-black">
        {value}
      </span>

      {isActive && <SelectedIcon />}
    </button>
  )
}
