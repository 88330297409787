import { Room } from "types/pretty-api-types"

export const DropdownOptions = ({
  rooms,
  onSelect
}: {
  rooms: Room[]
  onSelect: (room: Room) => void
}) => (
  <div className="flex flex-col overflow-auto max-h-64 ring-1 ring-one-gray-300 rounded-md shadow-md absolute mt-12 w-full">
    {rooms.map((room) => (
      <button
        key={room.id}
        className="w-full py-2 px-4 flex justify-between items-center bg-white hover:bg-one-gray-100"
        onClick={() => onSelect(room)}
      >
        <div>{room.name}</div>
      </button>
    ))}
  </div>
)
