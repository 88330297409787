import { useRouterContext, useTranslate } from "@pankod/refine-core"
import { useOutsideClick } from "hooks/useOutsideClick"
import { IDevice } from "interfaces"
import { useRef } from "react"
import { DeviceStatusIndicator } from "./DeviceStatusIndicator"
import { DeviceImage } from "./DeviceImage"

export const DeviceContainer = ({
  id,
  device,
  isDeviceShow,
  isLinkedToDevice
}: {
  id: number
  device: IDevice
  isDeviceShow?: boolean
  isLinkedToDevice?: boolean
}) => {
  const translate = useTranslate()
  const dropdownMenuRef = useRef<HTMLDivElement>(null)
  const dropdownButtonRef = useRef<HTMLButtonElement>(null)

  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const isDevicePaired = (): string => {
    if (device.model.type === "Kiosk" || device.model.type === "Room Display") {
      // Active if pin_code is null
      return device.pin_code === null ? "active" : "inactive"
    } else if (device.model.type === "Printer") {
      // Active if it is linked to another device
      return isLinkedToDevice ? "active" : "inactive"
    }
    return "inactive"
  }

  const [dropdown, setDropdown] = useOutsideClick(
    dropdownMenuRef,
    dropdownButtonRef
  )

  const handlePropagationClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: () => void
  ) => {
    e.stopPropagation()
    callback()
  }

  return (
    <div>
      <div
        key={id.toString()}
        className={`w-64 h-60 px-4 py-2 relative flex flex-col items-center justify-between border-2 rounded-xl bg-white shadow-sm border-gray-100 z-5 ${
          !isDeviceShow && "hover:border-blue-600 cursor-pointer"
        }`}
        onClick={() => {
          push(`/devices/${device.id}`)
        }}
      >
        <div className="flex flex-col space-y-4 w-full">
          <div className="flex-shrink-0 w-full">
            <div className="absolute">
              <DeviceStatusIndicator
                status={isDevicePaired() === "active" ? "active" : "inactive"}
              />
            </div>
            <div className="pt-4 px-8">
              <div className="h-24">
                <DeviceImage type={device.model.type} />
              </div>
            </div>
          </div>
          <div className="text-left max-w-56">
            <h3 className="font-medium text-ellipsis overflow-hidden">
              {device.name}
            </h3>
            <p className="text-one-gray-500 truncate">{device.model.type}</p>
            <p className="text-one-gray-500 truncate">{device.model.name}</p>
          </div>
        </div>
        {!isDeviceShow && (
          <div className="absolute top-4 right-4">
            <button
              ref={dropdownButtonRef}
              className="flex items-center justify-center w-10 h-10 z-40 rounded-md bg-gray-50 hover:bg-gray-100"
              onClick={(e) =>
                handlePropagationClick(e, () => setDropdown(!dropdown))
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="currentColor"
                className="w-6 h-6"
              >
                <circle cx="16" cy="8" r="2"></circle>
                <circle cx="16" cy="16" r="2"></circle>
                <circle cx="16" cy="24" r="2"></circle>
              </svg>
            </button>
            {dropdown && (
              <div
                ref={dropdownMenuRef}
                className="flex flex-col mt-1 absolute border border-gray-100 bg-white rounded-md shadow-sm z-10"
              >
                <button
                  className="flex grow px-4 py-2 text-gray-600 whitespace-nowrap hover:bg-gray-100"
                  onClick={(e) =>
                    handlePropagationClick(e, () =>
                      push(`/devices/${device.id}`)
                    )
                  }
                >
                  {translate("buttons.viewEdit")}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
