import { useNavigation, useRegister, useTranslate } from "@pankod/refine-core"
import React, { useCallback, useState, ChangeEvent, KeyboardEvent } from "react"
import ErrorText from "components/formMessage"
import customToast from "utilities/toastHelper"
import { RegisterVariables } from "utilities/types"
import { LOG, Logger } from "utilities/logger"
import { validateEmail } from "utilities/formValidator"
import {
  EyeIcon,
  EyeSlashIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline"
import { Tooltip } from "react-tooltip"
import { Logo } from "icons/icons"
import { SyModal } from "components/new/shared"
import { ClipLoader } from "react-spinners"
import { MIN_PASSWORD_LENGTH } from "utilities/passwordValidator"

type FormFields = {
  email: string
  company: string
  firstName: string
  lastName: string
  password: string
}

export const RegisterForm = () => {
  const [formFields, setFormFields] = useState<FormFields>({
    email: "",
    company: "",
    firstName: "",
    lastName: "",
    password: ""
  })
  const [errors, setErrors] = useState<Partial<FormFields>>({})
  const [passwordVisible, setPasswordVisible] = useState(false)
  const { mutate: register } = useRegister<RegisterVariables>()
  const { push } = useNavigation()
  const translate = useTranslate()
  const [
    confirmOrganizationNameModalOpen,
    setConfirmOrganizationNameModalOpen
  ] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value })
  }

  const validate = (): Partial<FormFields> => {
    const newErrors: Partial<FormFields> = {}
    Object.entries(formFields).forEach(([key, value]) => {
      if (!value) {
        newErrors[key as keyof FormFields] = `Please fill ${key} field.`
      }
    })

    newErrors.email = validateEmail(formFields.email) || newErrors.email

    if (formFields.password.length < MIN_PASSWORD_LENGTH) {
      newErrors.password = translate(
        "pages.login.register.passwordValidationError"
      )
    }

    return newErrors
  }

  const handleRegister = () => {
    setIsLoading(true)
    register(formFields, {
      onSuccess: () => {
        setIsLoading(false)
        customToast.success(translate("pages.login.register.registerSuccess"))
        void Logger().log(LOG.REGISTER_SUCCESS)
        push("/login")
      },
      onError: (error) => {
        setIsLoading(false)
        customToast.error(translate("pages.login.register.registerError"))
        void Logger().error(LOG.REGISTER_ERROR, error.toString())
      }
    })
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") handleRegister()
  }

  const handleKeyPressCallback = useCallback(handleKeyPress, [formFields])

  return (
    <div className="flex justify-center items-center w-full min-h-full">
      <div className="w-full lg:w-128">
        <Logo className="h-8 mb-8" />
        <h1
          className="font-kanit font-medium text-3xl mb-6 text-center lg:text-left"
          data-testid="register-create-account-title"
        >
          {translate("pages.login.register.createAccount")}
        </h1>

        <div className="space-y-6">
          <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
            {["firstName", "lastName"].map((field) => (
              <div key={field} className="flex-1">
                <label htmlFor={field} className="font-medium">
                  {translate(`pages.login.register.${field}`)}
                </label>
                <input
                  id={field}
                  name={field}
                  type="text"
                  placeholder={translate(
                    `pages.login.register.${field}Placeholder`
                  )}
                  className="mt-2 block w-full rounded-md border-0 py-2 px-4 shadow-sm ring-1 ring-inset ring-one-gray-200 placeholder:text-one-gray-400 focus:ring-2 focus:ring-inset focus:ring-one-rose-400"
                  value={formFields[field as keyof FormFields]}
                  data-testid={`register-create-account-input-${field}`}
                  onChange={handleChange}
                  onKeyDown={handleKeyPressCallback}
                />
                {errors[field as keyof FormFields] && (
                  <p className="mt-2">
                    {ErrorText(errors[field as keyof FormFields])}
                  </p>
                )}
              </div>
            ))}
          </div>

          {["email", "company"].map((field) => (
            <div key={field}>
              <label htmlFor={field} className="font-medium">
                {translate(`pages.login.register.${field}`)}
              </label>
              <div className="relative">
                <input
                  id={field}
                  name={field}
                  type="text"
                  placeholder={translate(
                    `pages.login.register.${field}Placeholder`
                  )}
                  className="mt-2 block w-full rounded-md border-0 py-2 pl-4 pr-12 shadow-sm ring-1 ring-inset ring-one-gray-200 placeholder:text-one-gray-400 focus:ring-2 focus:ring-inset focus:ring-one-rose-400"
                  value={formFields[field as keyof FormFields]}
                  data-testid={`register-create-account-input-${field}`}
                  onChange={handleChange}
                  onKeyDown={handleKeyPressCallback}
                />
                {field === "company" && (
                  <div>
                    <button
                      data-tooltip-id="organization-name-tooltip"
                      data-tooltip-content={translate(
                        `pages.login.register.organizationTooltip`
                      )}
                      data-tooltip-place="top"
                      className="absolute top-1/2 right-3 transform -translate-y-1/2"
                      data-testid="organization-name-tooltip-button"
                    >
                      <InformationCircleIcon className="h-6 w-6 hover:text-one-rose-400" />
                    </button>
                    <Tooltip
                      id="organization-name-tooltip"
                      className="max-w-xs break-words p-2 bg-gray-800 text-white rounded-md"
                      style={{ zIndex: 99 }}
                    />
                  </div>
                )}
              </div>
              {errors[field as keyof FormFields] && (
                <p className="mt-2">
                  {ErrorText(errors[field as keyof FormFields])}
                </p>
              )}
            </div>
          ))}

          <div>
            <label htmlFor="password" className="font-medium">
              {translate("pages.login.password")}
            </label>
            <div className="relative">
              <input
                id="password"
                type={passwordVisible ? "text" : "password"}
                name="password"
                placeholder={translate(
                  "pages.login.register.passwordPlaceholder"
                )}
                className="mt-2 block w-full rounded-md border-0 py-2 pl-4 pr-12 shadow-sm ring-1 ring-inset ring-one-gray-200 placeholder:text-one-gray-400 focus:ring-2 focus:ring-inset focus:ring-one-rose-400"
                value={formFields.password}
                data-testid="register-create-account-input-password"
                onChange={handleChange}
                onKeyDown={handleKeyPressCallback}
              />
              <button
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="absolute top-1/2 right-3 transform -translate-y-1/2"
              >
                {passwordVisible ? (
                  <EyeSlashIcon className="h-6 w-6 hover:text-one-rose-400" />
                ) : (
                  <EyeIcon className="h-6 w-6 hover:text-one-rose-400" />
                )}
              </button>
            </div>
            {errors.password && (
              <p className="mt-2">{ErrorText(errors.password)}</p>
            )}
          </div>

          <div>
            <button
              onClick={() => {
                const validationErrors = validate()
                setErrors(validationErrors)
                if (
                  Object.values(validationErrors).some((e) => e !== undefined)
                )
                  return
                setConfirmOrganizationNameModalOpen(true)
              }}
              className="flex w-full justify-center rounded-lg bg-one-rose-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-one-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-400"
              data-testid="register-create-account-register-button"
            >
              {translate("pages.login.register.register")}
            </button>
            <SyModal
              open={confirmOrganizationNameModalOpen}
              onClose={() => setConfirmOrganizationNameModalOpen(false)}
              title={translate(
                "pages.login.register.confirmYourOrganizationName"
              )}
              size="lg"
              data-testid="confirm-organization-name-modal"
            >
              <div className="flex flex-col mt-6 mb-4 items-center">
                <h1
                  className="font-medium text-2xl mb-6"
                  data-testid="confirm-organization-name-title"
                >
                  &laquo;{formFields.company}&raquo;
                </h1>
                <p
                  className="text-center"
                  data-testid="confirm-organization-name-explanation"
                >
                  {translate(
                    "pages.login.register.confirmYourOrganizationNameExplanation"
                  )}
                </p>
              </div>
              <div className="flex flex-row justify-between gap-4">
                <button
                  className="w-full mt-4 ring-1 ring-one-gray-300 border rounded-lg border-one-gray-400 text-one-gray-900 px-4 py-2 font-medium"
                  onClick={() => setConfirmOrganizationNameModalOpen(false)}
                  data-testid="confirm-organization-name-cancel-button"
                >
                  {translate("pages.login.register.cancel")}
                </button>
                <button
                  disabled={isLoading}
                  onClick={handleRegister}
                  className={`w-full mt-4 rounded-lg bg-one-rose-400 shadow-sm text-white px-4 py-2 font-medium hover:bg-one-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-400${
                    isLoading ? "cursor-not-allowed" : ""
                  }`}
                  data-testid="confirm-organization-name-register-button"
                >
                  {isLoading ? (
                    <div className="flex justify-center items-center w-full">
                      <ClipLoader
                        color="#fff"
                        loading={true}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : (
                    translate("pages.login.register.saveAndContinue")
                  )}
                </button>
              </div>
            </SyModal>
          </div>

          <div className="flex flex-col">
            <p className="text-left">
              {translate("pages.login.register.bySigningUp")}
              <a
                href="https://app.systam.io/terms-of-service"
                target="_blank"
                rel="noreferrer"
                className="text-center mx-1 font-medium text-one-rose-400 hover:text-one-rose-500"
              >
                {translate("pages.login.register.serviceAgreement")}
              </a>
            </p>
            <div className="flex mt-16">
              {translate("pages.login.register.alreadyHaveAccount")}
              <button
                onClick={() => push("/login")}
                className="ml-4 font-medium text-one-rose-400 hover:text-one-rose-500"
              >
                {translate("pages.login.login")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
