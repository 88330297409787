import {
  useCustomMutation,
  useOne,
  usePermissions,
  useTranslate
} from "@pankod/refine-core"
import { IWorkspace } from "interfaces"
import { useState, useEffect } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { Logger, LOG } from "utilities/logger"
import customToast from "utilities/toastHelper"

export const WorkspaceNameChange = () => {
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")
  const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE) ?? ""
  const [savedValue, setSavedValue] = useState("")
  const [settingValue, setSettingValue] = useState("")
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()

  const { isLoading, data: workspaceData } = useOne<IWorkspace>({
    resource: "workspaces",
    id: selectedWorkspaceId
  })

  const endpoint = "workspaces/{id}"

  useEffect(() => {
    setSettingValue(workspaceData?.data?.name ?? "")
  }, [workspaceData])

  const updateSetting = (value: string) => {
    if (savedValue === settingValue || selectedWorkspaceId === "") {
      return
    }

    updateMutation(
      {
        url: endpoint.replace("{id}", selectedWorkspaceId),
        method: "patch",
        values: { name: value }
      },
      {
        onSuccess: (_variables, _context) => {
          setSavedValue(settingValue)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.WorkspaceName`)
            })
          )
          void Logger().log(LOG.EDIT_SETTING, "WorkspaceName")
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.WorkspaceName`)
            })
          )
          void Logger().error(
            LOG.EDIT_SETTING,
            `WorkspaceName: ${JSON.stringify(_error)}`
          )
        }
      }
    )
  }

  return (
    <div className="flex flex-col gap-2 flex-1 mb-8 w-full">
      <h1 className="text-md font-medium">
        {translate("resources.WorkspaceName")}
      </h1>
      <input
        disabled={!canEdit && !isLoading}
        type={"text"}
        className={`bg-white w-full border text-sm rounded-md block p-2.5 ${
          !canEdit
            ? "!bg-gray-100 border-gray-300 cursor-not-allowed"
            : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:border-2"
        }`}
        value={settingValue}
        onChange={(event) => setSettingValue(event.target.value)}
        onBlur={() => updateSetting(settingValue)}
      ></input>
    </div>
  )
}
