import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next" // Add this import

export const DropdownButton = ({
  selectedRoomName,
  isOpen,
  onClick,
  placeholder = "buttons.selectARoom"
}: {
  selectedRoomName: string
  isOpen: boolean
  onClick: () => void
  placeholder?: string
}) => {
  const { t } = useTranslation()

  return (
    <div className="w-full ring-1 ring-one-gray-300 rounded-md flex justify-between items-center h-10">
      <button
        id="button_devices_dropdown"
        className="w-full flex py-2 px-4"
        onClick={onClick}
      >
        {selectedRoomName ? (
          <p className="w-full text-start">{selectedRoomName}</p>
        ) : (
          <p className="w-full text-start text-gray-500">{t(placeholder)}</p>
        )}
        <FontAwesomeIcon
          className="text-one-gray-500"
          icon={isOpen ? faCaretUp : faCaretDown}
          size="lg"
        />
      </button>
    </div>
  )
}
