import { AxiosInstance } from "axios"
import { IUser } from "interfaces"
import i18next from "./i18n"

export enum LANGUAGE_IDS {
  FIN,
  EN
}

export enum INF_SCROLL_QUERY_KEY_TYPES {
  VISITS = "infScrollVisits",
  VISITORS = "infScrollVisitors",
  USERS = "infScrollUsers",
  HOSTS = "infScrollHosts",
  ROOMS = "infScrollRooms",
  WORKSPACE_HOSTS = "infScrollWorkspaceHosts"
}

export enum INTEGRATION_TYPES {
  AZURE = "Azure",
  GOOGLE = "Google",
}

export enum LOCAL_STORAGE_KEYS {
  CALENDER_PROVIDER = "CalendarProviders",
  VISITS = "Visits",
  VISITORS = "Visitors",
  SCOPE_ID = "SCOPE_ID",
  HOSTS = "Hosts",
  LOBBIES = "Lobbies",
  ORGANIZATIONS = "Organizations",
  USERS = "Users",
  USER_ID = "userId",
  USER_TYPE = "type",
  USER_ROE = "role",
}

export enum QUERY_KEYS {
  WORKSPACE_SETTING = "workspaceSetting",
  CALENDAR_INTEGRATION = "calendarIntegration"
}

export type DATE_TIME_INPUT_TYPES = "date" | "datetime-local" | "time"

export type SETTING_DATA_TYPES =
  | "Boolean"
  | "Color"
  | "Date"
  | "DateTime"
  | "Dropdown"
  | "Email"
  | "File"
  | "Media"
  | "Number"
  | "Password"
  | "Phone"
  | "Picture"
  | "Text"
  | "TextArea"
  | "Time"
  | "TimeZone"
  | "Url"
  | "PrintingBoolean"
  | "PreArrivalEmailBody"
  | "ConfirmBooking"

export type SETTING_DATA_KEYS =
  | "AskVisitorEmail"
  | "AskVisitorPhone"
  | "AutomaticVisitorSignOut"
  | "DefaultLanguage"
  | "EmailSender"
  | "EmailSenderName"
  | "FooterLogoUrl"
  | "HeaderLogoUrl"
  | "IdleSubtitle"
  | "IdleSubtitleFontColor"
  | "IdleSubtitleVisible"
  | "IdleTitle"
  | "IdleTitleFontColor"
  | "IdleTitleVisible"
  | "LobbyEmailAddress"
  | "LobbyPhoneNumber"
  | "Logo"
  | "OrganizationAddress"
  | "PrintCard"
  | "PrivacyPolicyEn"
  | "PrivacyPolicyFi"
  | "PrivacyPolicyDk"
  | "PrivacyPolicyPl"
  | "RoomsLogo"
  | "RoomsBackgroundPicture"
  | "SendEmail"
  | "SendLobbyNotifications"
  | "SendSms"
  | "TakePhoto"
  | "TermsOfVisitEn"
  | "TermsOfVisitFi"
  | "TermsOfVisitDk"
  | "TermsOfVisitPl"
  | "UseSignature"

export type FormValues = {
  setting_metadata_key: string
  value: string
}

export type LoginVariables = {
  username: string
  password: string
}

export type RegisterVariables = {
  email: string
  company: string
  firstName: string
  lastName: string
  password: string
}

export type ForgotPasswordVariables = {
  username: string
}

export type UpdatePasswordVariables = {
  token: string
  password: string
  confirmPassword: string
}

export const UserType = {
  adminUser: "AdminUser",
  user: "User",
  device: "Device"
} as const

export const VisitState = {
  expected: 1,
  signedIn: 2,
  signedOut: 3,
  removed: 4
} as const

export const VisitStateStatus = {
  1: () => i18next.t("visitor_expected"),
  2: () => i18next.t("visitor_signed_in"),
  3: () => i18next.t("visitor_signed_out")
} as const

export type AccessTokenResponse = {
  user: IUser
  access_token: string
  refresh_token: string
  token_type: string
}

export type ForgotPasswordResponse = {
  success: boolean
}

export type RefreshTokenRequest = {
  shouldRetry: boolean
  instance: AxiosInstance
}

export type UpdatePasswordResponse = {
  success: boolean
}

/**
 * Read more about permissions here
 * https://github.com/systamfi/systam-rooms-api/wiki/Hierarchy-2.0
 */
export enum permissionType {
  all = "All",
  organization = "Organization",
  lobby = "Lobby",
  device = "Device",
  host = "Host",
  room = "Room",
  setting = "Setting",
  user = "User",
  visit = "Visit",
  visitor = "Visitor",
  workspace = "Workspace",
  /**
   * This is a fake permission type for frontend.
   * You can use it to explicitly make resource permission true at resources.tsx
   *
   * Eg: Some of the resources like Notifications do not have permission for it,
   * so to make it visible to all users, we can use this permission type
   */
  explicit_allow = "explicit_allow",
  explicit_deny = "explicit_deny"
}

export interface VisitorResponseModel {
  id?: number
  uuid?: string
  first_name?: string
  last_name?: string
  contact_details?: ContactDetail[]
  company?: string
  picture?: string
  language?: Language
  created?: Date
  updated?: Date
}

export interface ContactDetail {
  id?: number
  uuid?: string
  type?: string
  value?: string
  primary?: boolean
  notifications?: boolean
  created?: Date
  updated?: Date
}

export interface Language {
  id?: number
  uuid?: string
  tag?: string
  name?: string
}
